
// Vue reactivity
import { defineComponent, onMounted, reactive, ref, watch, onUnmounted } from 'vue';

// icons
import { close, filter, closeCircle, checkmark, downloadOutline, flagOutline, barChart, list, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonGrid, IonCol, IonRow, IonButtons, IonButton, IonIcon,
          IonList, IonItem, IonLabel, IonDatetime, IonDatetimeButton, IonSpinner, IonModal, IonNote,
          IonSelect, IonSelectOption, IonPopover, IonFab, IonFabButton, IonSegment, IonSegmentButton,
          modalController, loadingController } from '@ionic/vue';

// composables
import { utils } from '@/composables/utils';
import { utilsDevice } from '@/composables/utilsDevice';
import { useI18n } from 'vue-i18n';

// services
import ReportService from '@/services/ReportService';
import DeviceService from '@/services/DeviceService';

// data visualization
import { Grid, h, } from "gridjs";
import "gridjs/dist/theme/mermaid.css";

// csv export
import Papa from 'papaparse';

// amCharts 5
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default defineComponent({
  name: 'HelmetReportModal',
  props: [
    "projectId",
    "project",
    "allLocations",
  ],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonGrid, IonCol, IonRow, IonButtons, IonButton, IonIcon,
                IonList, IonItem, IonLabel, IonDatetime, IonDatetimeButton, IonSpinner, IonModal, IonNote,
                IonSelect, IonSelectOption, IonPopover, IonFab, IonFabButton, IonSegment, IonSegmentButton,
                Grid, },
  setup(props) {
    const { t } = useI18n();

    const { downloadFile, closeModal, tStr, formatDate, getLocalizedStr, openScaffoldReportModal, uniqueId, openImageModal, getRelativeDate, } = utils();
    const { getRotateDegreeFromAcc, pointEntityTypes, alertIcons, formatVal, } = utilsDevice();
    const loadingData = ref(false);
    const popoverState = reactive({
      event: false,
      location: false,
      priority: false,
    });
    const popoverEvent = ref();
    const setPopoverOpen = (popoverKey: any, state: boolean, ev: any) => {
      popoverEvent.value = ev; 
      popoverState[popoverKey] = state;
    };
    
    const filters = reactive({
      fromTs: null,
      toTs: null,
      targetDataTable: 'helmets',
      location: "",
      event: "",
      priority: null,
      viewType: 'table',
    });

    // Watch fromTs changes to adjust toTs relatively
    watch(() => filters.fromTs, (newFromTs, oldFromTs) => {
      if (newFromTs && oldFromTs && filters.toTs) {
        const oldFromDate = new Date(oldFromTs);
        const newFromDate = new Date(newFromTs);
        const timeDiff = newFromDate.getTime() - oldFromDate.getTime();
        
        const oldToDate = new Date(filters.toTs);
        filters.toTs = new Date(oldToDate.getTime() + timeDiff).toISOString();
      }
    });

    let grid;
    const gridDivId = `wrapper-${uniqueId()}`;

    // Chart refs
    const chartIds = {
      posturePie: `posture-pie-${uniqueId()}`,
      onlinePie: `online-pie-${uniqueId()}`,
      workingPie: `working-pie-${uniqueId()}`,
      floorBar: `floor-bar-${uniqueId()}`,
      batteryBar: `battery-bar-${uniqueId()}`,
    };
    const chartRoots = {
      posturePie: null,
      onlinePie: null,
      workingPie: null,
      floorBar: null,
      batteryBar: null,
    };

    // Chart initialization functions
    const initPieChart = (targetKey, data, title) => {
      if (chartRoots[targetKey]) chartRoots[targetKey].dispose();

      // Create root element
      const chartRoot = am5.Root.new(chartIds[targetKey]);
      chartRoots[targetKey] = chartRoot;
      chartRoot.setThemes([am5themes_Animated.new(chartRoot)]);

      // Create chart
      const chart = chartRoot.container.children.push(
        am5percent.PieChart.new(chartRoot, {
          layout: chartRoot.horizontalLayout,
        })
      );

      chart.children.unshift(am5.Label.new(chartRoot, {
        text: title,
        fontSize: 18,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
      }));

      // Create series
      const series = chart.series.push(
        am5percent.PieSeries.new(chartRoot, {
          name: "Series",
          valueField: "value",
          categoryField: "category",
        })
      );
      series.labels.template.setAll({
        text: "{category}: {value}",
        radius: 4,
        fontSize: 12,
        fill: am5.color(0x000000)
      });
      series.ticks.template.setAll({
        length: 10,
        stroke: am5.color(0x000000)
      });

      return series;
    }

    /**
     * Helmet posture: 1: Wearing, -1: Not Wearing
     */
    const initPosturePieChart = (data) => {
      const series = initPieChart('posturePie', data, tStr('安全帽配戴狀況', 'Helmet Usage'));

      const wearingCount = data.filter(h => h.helmet?.currWorkRecordId && h.helmet?.posture === 1).length;
      const notWearingCount = data.length - wearingCount;
      
      series.data.setAll([
        { category: tStr('有戴帽', 'Wearing'), value: wearingCount, fill: am5.color(0x4bC0C0) },
        {  category: tStr('無戴帽', 'Not Wearing'), value: notWearingCount, fill: am5.color(0xff6384) }
      ]);
    };

    const initWorkingPieChart = (data) => {
      const series = initPieChart('workingPie', data, tStr('開工人數統計', 'On-site Workers'));

      // Set data
      const activeCount = data.filter(h => (h.currWorkRecordId != null)).length;
      const inactiveCount = data.length - activeCount;
      
      series.data.setAll([
        { category: tStr('工作中', 'Working'), value: activeCount, fill: am5.color(0x4bC0C0) },
        {  category: tStr('休息中', 'Not Working'), value: inactiveCount, fill: am5.color(0xC9CBCF) }
      ]);
    };

    /**
     * Helmet is online: based on lastConnectTime
     */
    const initOnlinePieChart = (data) => {
      const series = initPieChart('onlinePie', data, tStr('設備在線狀態', 'Device Status'));

      // Set data
      const now = new Date().getTime();
      const onlineThreshold = 60 * 60 * 1000; // 60 minutes
      //const onlineCount = data.filter(h => (now - new Date(h.lastConnectTime).getTime()) <= onlineThreshold).length;
      const onlineCount = data.length; // for Demo
      const offlineCount = data.length - onlineCount;
      
      series.data.setAll([
        { category: tStr('在線', 'Online'), value: onlineCount, fill: am5.color(0x4bC0C0) },
        {  category: tStr('離線', 'Offline'), value: offlineCount, fill: am5.color(0xC9CBCF) }
      ]);
    };

    const initFloorBarChart = (data) => {
      if (chartRoots.floorBar) chartRoots.floorBar.dispose();

      // Create root element
      const chartRoot = am5.Root.new(chartIds.floorBar);
      chartRoots.floorBar = chartRoot;
      chartRoot.setThemes([am5themes_Animated.new(chartRoot)]);

      // Create chart
      const chart = chartRoot.container.children.push(am5xy.XYChart.new(chartRoot, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        layout: chartRoot.verticalLayout
      }));

      chart.children.unshift(am5.Label.new(chartRoot, {
        text: tStr('樓層分佈', 'Floor Distribution'),
        fontSize: 18,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
      }));

      // Create axes
      const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(chartRoot, {
        categoryField: "floor",
        renderer: am5xy.AxisRendererX.new(chartRoot, {
          minGridDistance: 60, // Ensure labels don't merge
        }),
        tooltip: am5.Tooltip.new(chartRoot, {})
      }));

      const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(chartRoot, {
        renderer: am5xy.AxisRendererY.new(chartRoot, {})
      }));

      // Create series
      const series = chart.series.push(am5xy.ColumnSeries.new(chartRoot, {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "count",
        categoryXField: "floor",
        tooltip: am5.Tooltip.new(chartRoot, {
          labelText: "{categoryX}: {valueY}"
        })
      }));

      series.columns.template.setAll({
        fill: am5.color(0x36A2EB),
        strokeOpacity: 0,
        cursorOverStyle: "pointer",
        tooltipText: "{categoryX}: {valueY}",
        interactive: true
      });

      // Set data
      const floorCounts = {};
      data.forEach(h => {
        const floor = h.floor || tStr('未知', 'Unknown');
        floorCounts[floor] = (floorCounts[floor] || 0) + 1;
      });

      const sortedFloors = Object.keys(floorCounts).sort((a, b) => {
        if (a === tStr('未知', 'Unknown')) return 1;
        if (b === tStr('未知', 'Unknown')) return -1;
        return parseInt(a) - parseInt(b);
      });

      const chartData = sortedFloors.map(floor => ({
        floor,
        count: floorCounts[floor]
      }));

      xAxis.data.setAll(chartData);
      series.data.setAll(chartData);

      chart.set("scrollbarX", am5.Scrollbar.new(chartRoot, {
        orientation: "horizontal"
      }));

      chart.set("cursor", am5xy.XYCursor.new(chartRoot, {
        behavior: "zoomX"
      }));
    };

    const initBatteryBarChart = (data) => {
      if (chartRoots.batteryBar) chartRoots.batteryBar.dispose();

      // Create root element
      const chartRoot = am5.Root.new(chartIds.batteryBar);
      chartRoots.batteryBar = chartRoot;
      chartRoot.setThemes([am5themes_Animated.new(chartRoot)]);

      // Create chart
      const chart = chartRoot.container.children.push(am5xy.XYChart.new(chartRoot, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        layout: chartRoot.verticalLayout
      }));

      chart.children.unshift(am5.Label.new(chartRoot, {
        text: tStr('電池電量分佈', 'Battery Distribution'),
        fontSize: 18,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
      }));

      // Create axes
      const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(chartRoot, {
        categoryField: "level",
        renderer: am5xy.AxisRendererX.new(chartRoot, {
          minGridDistance: 60, // Ensure labels don't merge
        }),
        tooltip: am5.Tooltip.new(chartRoot, {})
      }));

      const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(chartRoot, {
        renderer: am5xy.AxisRendererY.new(chartRoot, {})
      }));

      // Create series
      const series = chart.series.push(am5xy.ColumnSeries.new(chartRoot, {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "count",
        categoryXField: "level",
        tooltip: am5.Tooltip.new(chartRoot, {
          labelText: "{categoryX}: {valueY}"
        })
      }));

      series.columns.template.setAll({
        strokeOpacity: 0,
        templateField: "columnSettings",
        cursorOverStyle: "pointer",
        tooltipText: "{categoryX}: {valueY}",
        interactive: true
      });

      // Set data
      const batteryLevels = [1, 0.75, 0.5, 0.25, 0];
      const chartData = batteryLevels.map(level => {
        const count = data.filter(h => h.helmet?.batteryLevel === level).length;
        const color = level >= 0.75 ? 0x4bC0C0 :
                     level >= 0.5 ? 0xFFCD56 :
                     level >= 0.25 ? 0xFF9F40 :
                     0xFF6384;
        
        return {
          level: `${level * 100}%`,
          count,
          color: am5.color(color)
        };
      });

      xAxis.data.setAll(chartData);
      series.data.setAll(chartData);

      chart.set("scrollbarX", am5.Scrollbar.new(chartRoot, {
        orientation: "horizontal"
      }));

      chart.set("cursor", am5xy.XYCursor.new(chartRoot, {
        behavior: "zoomX"
      }));
    };

    // Update refreshData to handle chart initialization
    const refreshData = async () => {
      if (grid) grid.destroy(); // destroy for re-init

      const { fromTs, toTs, targetDataTable, location, event, priority } = filters;
      
      loadingData.value = true;
      try {
        const response = await ReportService.queryData({ fromTs, toTs, targetDataTable, location, event, priority, projectId: props.projectId });
        const data = response.data;

        let rows = [], columns = [];

        if (filters.targetDataTable == 'helmets') {
          if (filters.viewType === 'chart') {
            // Initialize all charts
            initPosturePieChart(data);
            initOnlinePieChart(data);
            initWorkingPieChart(data);
            initFloorBarChart(data);
            initBatteryBarChart(data);
          } else {
            columns = ["ID", tStr('所屬工人', 'Related Worker'), tStr('開工中？', 'Working?'), tStr('有戴帽？', 'Wearing Helmet?'), tStr('估計樓層', 'Estimated Floor'),
                            tStr('最後上線', 'Last Online'), tStr("電量", "Battery Level"), tStr("充電中", "Is Charging?"),
                            tStr('SIM卡電話號碼', 'SIM Mobile Number'), tStr('裝置狀態', 'Device Status'), tStr('最後數據更新', 'Last Updated')];
            rows = data.map(r => {
              return [r.id, r.workerName, r.currWorkRecordId ? tStr('是', 'Yes') : tStr('否', 'No'), r.helmet.posture == 1 ? tStr('是', 'Yes') : tStr('否', 'No'),
                      r.floor, getRelativeDate(r.lastConnectTime), `${r.helmet.batteryLevel * 100}%`, r.isCharging == true ? tStr('是', 'Yes') : tStr('否', 'No'),
                      r.iccid || 'Unknown', r.isDeviceEnabled ? tStr('已啟用', 'Enabled') : tStr('已停用', 'Disabled'), formatDate(r.lastConnectTime, 'YYYY-MM-DD HH:mm')]
            });
          }
        }
        else if (filters.targetDataTable == 'helmet_work_records') {
          filters.viewType = 'table'; // only table view is supported

          const secondDiff = (date1: any, date2: any) => Math.abs(((new Date(date2)).getTime() - (new Date(date1)).getTime()) / 1000);
          columns = [
              tStr('開工時間', 'Start Time'),
              tStr('工人名稱', 'Worker Name'),
              tStr('最新位置', 'Latest Location'),
              tStr('總工作時數', 'Total Working Hrs'),
              {
                  name: tStr('工作軌跡', 'Working Path'),
                  formatter: (cell) => {
                      return h('div', {
                          style: { 
                              whiteSpace: 'pre-line',
                              wordBreak: 'break-word'
                          }
                      }, cell);
                  }
              },
              tStr('安全帽ID', 'Helmet ID')
          ];
          rows = data.map(r => {
              return [
                  formatDate(r.createdAt, 'YYYY-MM-DD HH:mm'),
                  r.workerName,
                  r.logs[0] ? r.logs[0].floor : "",
                  ((r.durationSeconds || secondDiff(r.createdAt, new Date())) / 3600).toFixed(1),
                  r.locationLogs,
                  r.helmetId
              ];
          });
        }
        
        if (filters.viewType == 'table') { // Render the data table
          grid = new Grid({
            sort: true,
            fixedHeader: true,
            pagination: { limit: 50, },
            height: '600px',
            width: '100%',
            columns,
            data: rows,
          }).render(document.querySelector(`#${gridDivId}`));
        }

        loadingData.value = false;
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    onMounted(() => {
      refreshData();
    })

    onUnmounted(() => {
      Object.values(chartRoots).forEach(root => {
        if (root) root.dispose();
      });
    });

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      close, filter, closeCircle, checkmark, downloadOutline,
      flagOutline, barChart, list,

      // variables
      loadingData,
      filters,
      gridDivId,
      chartIds,

      // methods
      t, tStr,
      openImageModal, getRelativeDate,
      closeModal, getLocalizedStr,
      fd: (d) => (formatDate(d, "YYYY-MM-DD HH:mm")),
      refreshData,

      // Filters
      updateFilters: (key, val) => {
        filters[key] = val;
        refreshData();
      },

      // popover
      popoverState, popoverEvent,
      setPopoverOpen,

      // export CSV
      exportTableToCSV: () => {
        const { columns, data } = grid.config;
        const objects = data.map(arr => {
          const obj = {};
          arr.forEach((value, index) => {
            const key = columns[index];
            obj[key.name || key] = value;
          });
          return obj;
        });
        const csv = '\ufeff' + Papa.unparse(objects);
        const csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
        const csvURL = window.URL.createObjectURL(csvData);
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'data.csv');
        tempLink.click();
      },
    }
  }
});
