
// Vue reactivity
import { defineComponent, onMounted, reactive, ref, watch, onUnmounted } from 'vue';

// icons
import { close, filter, closeCircle, checkmark, downloadOutline, flagOutline, barChart, list, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonGrid, IonCol, IonRow, IonButtons, IonButton, IonIcon,
          IonList, IonItem, IonLabel, IonDatetime, IonDatetimeButton, IonSpinner, IonModal, IonNote,
          IonSelect, IonSelectOption, IonPopover, IonFab, IonFabButton, IonSegment, IonSegmentButton,
          modalController, loadingController } from '@ionic/vue';

// composables
import { utils } from '@/composables/utils';
import { utilsDevice } from '@/composables/utilsDevice';
import { useI18n } from 'vue-i18n';

// services
import ReportService from '@/services/ReportService';
import DeviceService from '@/services/DeviceService';

// data visualization
import { Grid, h, } from "gridjs";
import "gridjs/dist/theme/mermaid.css";

// csv export
import Papa from 'papaparse';

// amCharts 5
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default defineComponent({
  name: 'AICamReportModal',
  props: [
    "projectId",
    "project",
    "allLocations",
  ],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonGrid, IonCol, IonRow, IonButtons, IonButton, IonIcon,
                IonList, IonItem, IonLabel, IonDatetime, IonDatetimeButton, IonSpinner, IonModal, IonNote,
                IonSelect, IonSelectOption, IonPopover, IonFab, IonFabButton, IonSegment, IonSegmentButton,
                Grid, },
  setup(props) {
    const { t } = useI18n();

    const { downloadFile, closeModal, tStr, formatDate, getLocalizedStr, openScaffoldReportModal, uniqueId, openImageModal, getRelativeDate, } = utils();
    const { getRotateDegreeFromAcc, pointEntityTypes, alertIcons, formatVal, } = utilsDevice();
    const loadingData = ref(false);
    const popoverState = reactive({
      event: false,
      location: false,
      priority: false,
    });
    const popoverEvent = ref();
    const setPopoverOpen = (popoverKey: any, state: boolean, ev: any) => {
      popoverEvent.value = ev; 
      popoverState[popoverKey] = state;
    };

    const availableEvents = ["無安全帽", "無反光衣", "人形"];
    
    const filters = reactive({
      fromTs: null,
      toTs: null,
      targetDataTable: 'device_alerts',
      location: "",
      event: "",
      priority: null,
      viewType: 'table',
    });

    // P1 alerts
    const updatePriority = async (alertId, priority) => {
      try {
        await DeviceService.updateDeviceAlert(alertId, { priority });
      } catch (error) {
        console.error('Failed to update priority:', error);
      }
    }

    // Watch fromTs changes to adjust toTs relatively
    watch(() => filters.fromTs, (newFromTs, oldFromTs) => {
      if (newFromTs && oldFromTs && filters.toTs) {
        const oldFromDate = new Date(oldFromTs);
        const newFromDate = new Date(newFromTs);
        const timeDiff = newFromDate.getTime() - oldFromDate.getTime();
        
        const oldToDate = new Date(filters.toTs);
        filters.toTs = new Date(oldToDate.getTime() + timeDiff).toISOString();
      }
    });

    let grid;
    const gridDivId = `wrapper-${uniqueId()}`;
    const chartIds = {
      eventPie: `event-pie-${uniqueId()}`,
      locationPie: `location-pie-${uniqueId()}`,
      dailyTrend: `daily-trend-${uniqueId()}`,
      hourlyDist: `hourly-dist-${uniqueId()}`,
    };
    const chartRoots = {
      eventPie: null,
      locationPie: null,
      dailyTrend: null,
      hourlyDist: null,
    };

    // Chart initialization functions
    const initEventPieChart = (data) => {
      if (chartRoots.eventPie) chartRoots.eventPie.dispose();

      const root = am5.Root.new(chartIds.eventPie);
      chartRoots.eventPie = root;
      root.setThemes([am5themes_Animated.new(root)]);

      const chart = root.container.children.push(
        am5percent.PieChart.new(root, {
          layout: root.horizontalLayout,
        })
      );

      // Add title
      chart.children.unshift(am5.Label.new(root, {
        text: tStr("事件分佈", "Event Distribution"),
        fontSize: 18,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
      }));

      const series = chart.series.push(
        am5percent.PieSeries.new(root, {
          name: "Events",
          valueField: "count",
          categoryField: "event"
        })
      );

      // Add labels
      series.labels.template.setAll({
        text: "{category}: {value}",
        radius: 4,
        fontSize: 12,
        fill: am5.color(0x000000)
      });
      series.ticks.template.setAll({
        length: 10,
        stroke: am5.color(0x000000)
      });

      // Event distribution data with simplified event names
      const eventData = Object.entries(data.reduce((acc, item) => {
        // Take only the first event before any "+"
        const mainEvent = item.event.split('+')[0].trim();
        acc[mainEvent] = (acc[mainEvent] || 0) + 1;
        return acc;
      }, {})).map(([event, count]) => ({ event, count }));

      series.data.setAll(eventData);
    };

    const initLocationPieChart = (data) => {
      if (chartRoots.locationPie) chartRoots.locationPie.dispose();

      const root = am5.Root.new(chartIds.locationPie);
      chartRoots.locationPie = root;
      root.setThemes([am5themes_Animated.new(root)]);

      const chart = root.container.children.push(
        am5percent.PieChart.new(root, {
          layout: root.horizontalLayout,
          innerRadius: 0
        })
      );

      // Add title
      chart.children.unshift(am5.Label.new(root, {
        text: tStr("位置分佈", "Location Distribution"),
        fontSize: 18,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
      }));

      const series = chart.series.push(
        am5percent.PieSeries.new(root, {
          name: "Locations",
          valueField: "count",
          categoryField: "location"
        })
      );

      // Add labels
      series.labels.template.setAll({
        text: "{category}: {value}",
        radius: 4,
        fontSize: 12,
        fill: am5.color(0x000000)
      });
      series.ticks.template.setAll({
        length: 10,
        stroke: am5.color(0x000000)
      });

      // Location distribution data with simplified location names
      const locationData = Object.entries(data.reduce((acc, item) => {
        // Extract text in last bracket
        const match = item.location.match(/\(([^)]+)\)$/);
        const simplifiedLocation = match ? match[1] : item.location;
        acc[simplifiedLocation] = (acc[simplifiedLocation] || 0) + 1;
        return acc;
      }, {})).map(([location, count]) => ({ location, count }));

      series.data.setAll(locationData);
    };

    const initDailyTrendChart = (data) => {
      if (chartRoots.dailyTrend) chartRoots.dailyTrend.dispose();

      const root = am5.Root.new(chartIds.dailyTrend);
      chartRoots.dailyTrend = root;
      root.setThemes([am5themes_Animated.new(root)]);

      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX",
          pinchZoomX: true,
          paddingTop: 40
        })
      );

      // Add title
      const title = root.container.children.unshift(am5.Label.new(root, {
        text: tStr("每日趨勢", "Daily Trend"),
        fontSize: 18,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
      }));

      // Add cursor
      chart.set("cursor", am5xy.XYCursor.new(root, {
        behavior: "none"
      }));

      // Create axes
      const xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          baseInterval: { timeUnit: "day", count: 1 },
          renderer: am5xy.AxisRendererX.new(root, {
            minGridDistance: 50,
            cellStartLocation: 0.1,
            cellEndLocation: 0.9
          }),
          tooltip: am5.Tooltip.new(root, {})
        })
      );

      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
          min: 0
        })
      );

      // Add series
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "count",
          valueXField: "date",
          tooltip: am5.Tooltip.new(root, {
            labelText: "{valueY}"
          })
        })
      );

      series.columns.template.setAll({
        cornerRadiusTL: 3,
        cornerRadiusTR: 3,
        strokeOpacity: 0
      });

      // Make columns hoverable
      series.columns.template.states.create("hover", {
        scale: 1.1,
        fill: am5.color(0x268ED1)
      });

      // Daily trend data
      const dailyData = Object.entries(data.reduce((acc, item) => {
        const date = new Date(item.startAt).toISOString().split('T')[0];
        acc[date] = (acc[date] || 0) + 1;
        return acc;
      }, {})).map(([date, count]) => ({ date: new Date(date).getTime(), count }));

      series.data.setAll(dailyData);

      // Make stuff animate on load
      series.appear(1000);
      chart.appear(1000, 100);
    };

    const initHourlyDistChart = (data) => {
      if (chartRoots.hourlyDist) chartRoots.hourlyDist.dispose();

      const root = am5.Root.new(chartIds.hourlyDist);
      chartRoots.hourlyDist = root;
      root.setThemes([am5themes_Animated.new(root)]);

      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX",
          pinchZoomX: true,
          paddingTop: 40
        })
      );

      // Add title
      const title = root.container.children.unshift(am5.Label.new(root, {
        text: tStr("每小時分佈", "Hourly Distribution"),
        fontSize: 18,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
      }));

      // Add cursor
      chart.set("cursor", am5xy.XYCursor.new(root, {
        behavior: "none"
      }));

      // Create axes
      const xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "hour",
          renderer: am5xy.AxisRendererX.new(root, {
            minGridDistance: 30,
            cellStartLocation: 0.1,
            cellEndLocation: 0.9
          }),
          tooltip: am5.Tooltip.new(root, {})
        })
      );

      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
          min: 0
        })
      );

      // Add series
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "count",
          categoryXField: "hour",
          tooltip: am5.Tooltip.new(root, {
            labelText: "{valueY}"
          })
        })
      );

      series.columns.template.setAll({
        cornerRadiusTL: 3,
        cornerRadiusTR: 3,
        strokeOpacity: 0
      });

      // Make columns hoverable
      series.columns.template.states.create("hover", {
        scale: 1.1,
        fill: am5.color(0x268ED1)
      });

      // Hourly distribution data
      const hourlyData = Array.from({ length: 12 }, (_, i) => ({
        hour: i === 0 ? '12 AM' : i < 12 ? `${i} AM` : '12 PM',
        count: data.filter(item => {
          const hour = new Date(item.startAt).getHours();
          return hour === (i === 0 ? 0 : i < 12 ? i : 12);
        }).length
      })).concat(
        Array.from({ length: 11 }, (_, i) => ({
          hour: `${i + 1} PM`,
          count: data.filter(item => {
            const hour = new Date(item.startAt).getHours();
            return hour === i + 13;
          }).length
        }))
      );

      xAxis.data.setAll(hourlyData);
      series.data.setAll(hourlyData);

      // Make stuff animate on load
      series.appear(1000);
      chart.appear(1000, 100);
    };

    // Update refreshData to handle chart initialization
    const refreshData = () => {
      if (grid) grid.destroy(); // destroy for re-init

      const { fromTs, toTs, targetDataTable, location, event, priority } = filters;
      
      loadingData.value = true;
      
      ReportService.queryData({ fromTs, toTs, targetDataTable, location, event, priority, projectId: props.projectId }).then(res => {
        const { data, error } = res;
        if (error) {
          loadingData.value = false;
          return;
        }
        if (filters.viewType === 'table') {
          // Define table columns
          const columns = [
            {
              name: "Time",
              formatter: (cell) => formatDate(cell, 'YYYY-MM-DD HH:mm:ss')
            },
            "Event",
            "Location",
            {
              name: "Image",
              formatter: (cell) => {
                const thumbnailUrl = cell.replace('/public', '/thumbnail');
                return h('img', {
                  src: thumbnailUrl,
                  style: 'height: 50px; cursor: pointer;',
                  onClick: () => openImageModal(cell, 'Alert Image')
                });
              }
            },
            {
              name: "Details",
              formatter: (_, row) => {
                const fullImageUrl = row.cells[3].data;
                return h('ion-button', {
                  onClick: () => openImageModal(fullImageUrl, `${formatDate(row.cells[0].data)} - ${row.cells[1].data}`),
                  style: 'font-size: 12px;'
                }, 'View');
              }
            },
            {
              name: "Priority",
              formatter: (_, row) => {
                const priority = row.cells[4].data;
                const alertId = row.cells[5].data;
                const bgColor = priority === 1 ? 'rgba(220, 53, 69, 0.15)' :
                               priority === 2 ? 'rgba(255, 193, 7, 0.15)' :
                               priority === 3 ? 'rgba(13, 110, 253, 0.15)' : 'transparent';
                return h('select', {
                  className: 'priority-select',
                  'data-alert-id': alertId,
                  style: `padding: 4px 8px; border-radius: 4px; border: 1px solid var(--ion-border-color); background: ${bgColor}; color: #000; cursor: pointer;`,
                  onChange: (e) => {
                    const target: any = e.target;
                    const newPriority = target.value ? parseInt(target.value) : null;
                    updatePriority(alertId, newPriority);
                  }
                }, [
                  h('option', { value: '' }, tStr('未設置', 'Not Set')),
                  h('option', { value: '1' }, 'P1'),
                  h('option', { value: '2' }, 'P2'),
                  h('option', { value: '3' }, 'P3')
                ]);
              }
            },
            {
              name: 'id',
              hidden: true
            }
          ];

          // Initialize grid with data
          grid = new Grid({
            sort: true,
            fixedHeader: true,
            pagination: { limit: 50 },
            height: '600px',
            width: '100%',
            columns,
            data: data.map(alert => ([
              alert.startAt,
              alert.event,
              alert.location,
              alert.imageLink,
              alert.priority,
              alert.id,
            ])),
            style: {
              table: {
                'font-size': '14px'
              }
            }
          })

          grid.render(document.querySelector(`#${gridDivId}`));

          // Add event listener for priority changes
          document.getElementById(gridDivId).addEventListener('change', (event) => {
            const target: any = event.target;
            if (target.classList.contains('priority-select')) {
              const alertId = target.dataset.alertId;
              const priority = parseInt(target.value) || null;
              updatePriority(alertId, priority);
            }
          });
        } else {
          // Render charts
          initEventPieChart(data);
          initLocationPieChart(data);
          initDailyTrendChart(data);
          initHourlyDistChart(data);
        }

        loadingData.value = false;
      });
    };

    // Cleanup charts on unmount
    onUnmounted(() => {
      Object.values(chartRoots).forEach(root => {
        if (root) root.dispose();
      });
    });

    onMounted(() => {
      refreshData();
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      close, filter, closeCircle, checkmark, downloadOutline,
      flagOutline, barChart, list,

      // variables
      loadingData,
      filters,
      gridDivId,
      chartIds,

      // methods
      t, tStr,
      openImageModal, getRelativeDate,
      closeModal, getLocalizedStr,
      fd: (d) => (formatDate(d, "YYYY-MM-DD HH:mm")),
      refreshData,

      // Filters
      updateFilters: (key, val) => {
        filters[key] = val;
        refreshData();
      },
      availableEvents,

      // popover
      popoverState, popoverEvent,
      setPopoverOpen,

      // export CSV
      exportTableToCSV: () => {
        const { columns, data } = grid.config;
        const objects = data.map(arr => {
          const obj = {};
          arr.forEach((value, index) => {
            const key = columns[index];
            obj[key.name || key] = value;
          });
          return obj;
        });
        const csv = '\ufeff' + Papa.unparse(objects);
        const csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
        const csvURL = window.URL.createObjectURL(csvData);
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'data.csv');
        tempLink.click();
      },

      // Priority / alert
      showP1Alerts: () => {
        // Reset all filters except viewType
        filters.fromTs = null;
        filters.toTs = null;
        filters.event = '';
        filters.location = '';
        // Set priority to P1
        filters.priority = 1;
        refreshData();
      },
    }
  }
});
