
// Vue reactivity
import { defineComponent, onMounted, reactive, ref, watch } from 'vue';

// icons
import { close, filter, closeCircle, checkmark, downloadOutline, enterOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonGrid, IonCol, IonRow, IonButtons, IonButton, IonIcon,
          IonList, IonItem, IonLabel, IonDatetime, IonDatetimeButton, IonSpinner, IonModal, IonNote,
          IonSelect, IonSelectOption, IonPopover, IonFab, IonFabButton,
          modalController, loadingController } from '@ionic/vue';

// composables
import { utils } from '@/composables/utils';
import { utilsDevice } from '@/composables/utilsDevice';
import { useI18n } from 'vue-i18n';

// services
import ReportService from '@/services/ReportService';

// data visualization
import { Grid, h, } from "gridjs";
import "gridjs/dist/theme/mermaid.css";

// csv export
import Papa from 'papaparse';

export default defineComponent({
  name: 'ScaffoldReportModal',
  props: [
    "asset",
    "workLocationId",
    "projectId",
    "project",
    "prefilledDataTable",
    "scaffoldSurfaceReportId",
    "timestamp",
  ],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonGrid, IonCol, IonRow, IonButtons, IonButton, IonIcon,
                IonList, IonItem, IonLabel, IonDatetime, IonDatetimeButton, IonSpinner, IonModal, IonNote,
                IonSelect, IonSelectOption, IonPopover, IonFab, IonFabButton,
                Grid, },
  setup(props) {
    const { t } = useI18n();

    const { downloadFile, closeModal, tStr, formatDate, getLocalizedStr, openScaffoldReportModal, uniqueId, } = utils();
    const { getRotateDegreeFromAcc, pointEntityTypes, getPointTypeObj, alertIcons, getDeviceLogMsg, formatVal, } = utilsDevice();
    const loadingData = ref(true);
    const filters = reactive({
      fromTs: null,
      toTs: null,
      targetDataTable: props.prefilledDataTable || (props.workLocationId ? 'scaffold_surface_reports' : 'device_logs'),

      // device_logs
      type: 'all',
      level: 'all',
      availableTypes: ['震動警報', '傾斜警報', '暑熱警告'],
      availableLevels: ['low', 'medium', 'high', '1', '2', '3', 'green', 'yellow', 'red', 'black'],
      availableFloors: [],

      // scaffold_floor_reports
      scaffoldSurfaceReportId: props.scaffoldSurfaceReportId,
      floor: 'all',
    });
    const popoverState = reactive({
      type: false,
      level: false,
    })
    const popoverEvent = ref();
    const setPopoverOpen = (popoverKey: any, state: boolean, ev: any) => {
      popoverEvent.value = ev; 
      popoverState[popoverKey] = state;
    };

    let grid;
    const gridDivId = `wrapper-${uniqueId()}`;
    const refreshData = () => {
      if (grid) grid.destroy(); // destroy for re-init

      const { fromTs, toTs, targetDataTable, type, level, scaffoldSurfaceReportId, floor, } = filters;
      const { asset, workLocationId, } = props;
      loadingData.value = true;
      ReportService.queryData({ assetId: asset?.id, fromTs, toTs, targetDataTable, type, level, workLocationId, scaffoldSurfaceReportId, floor, }).then(res => {
        loadingData.value = false;
        const { data, error } = res;

        let rows = [], columns = [];
        if (filters.targetDataTable == 'scaffold_surface_reports') {
          // Reports (floor level)
          const btnFormatter = (cell, row) => {
            return h('button', { className: 'table-action-btn', onClick: () => openScaffoldReportModal({
              prefilledDataTable: 'scaffold_floor_reports', scaffoldSurfaceReportId: cell,
              timestamp: row.cells[1].data,
            }) }, 'Details')
          }
          columns = [{ name: "Action", formatter: btnFormatter, sort: false, }, "Timestamp", { name: tStr("狀態", "Status"), formatter: (cell) => `${cell}` }, tStr("說明", "Descriptions"),
                      tStr('連牆器總數', 'Putlogs Total'), tStr('正常', 'Normal'), tStr('有問題', 'Abnormal'),
                      tStr('可能有問題', 'Suspicious'), tStr('沒有數據', 'No Data'), tStr('未連接', 'Not Connected'),
                      { name: "Avg. Temperature", formatter: (cell) => formatVal(cell, '°C') }, { name: "Avg. Humidity", formatter: (cell) => formatVal(cell, '%') },
                      { name: "Avg. Air Pressure", formatter: (cell) => formatVal(cell, 'hPa') }];
          rows = data.map(r => ([r.id, formatDate(r.timestamp), r.inspectionResult, r.reasoning, r.totalAnchors, r.totalAnchorsNormal, r.totalAnchorsAbnormal,
                                 r.totalAnchorsSuspect, r.totalAnchorsUnknown, r.totalAnchorsUnlinked, r.avgTemperature, r.avgHumidity, r.avgHectopascal]));
        }
        else if (filters.targetDataTable == 'scaffold_floor_reports') {
          // Reports (floor level)
          columns = ["Timestamp", "Floor", { name: tStr("狀態", "Status"), formatter: (cell) => `${cell}` }, tStr("原因", "Reasoning"),
                      tStr('連牆器總數', 'Putlogs Total'), tStr('正常', 'Normal'), tStr('有問題', 'Abnormal'),
                      tStr('可能有問題', 'Suspicious'), tStr('沒有數據', 'No Data'), tStr('未連接', 'Not Connected'),
                      { name: "Avg. Temperature", formatter: (cell) => formatVal(cell, '°C') }, { name: "Avg. Humidity", formatter: (cell) => formatVal(cell, '%') },
                      { name: "Avg. Air Pressure", formatter: (cell) => formatVal(cell, 'hPa') }];
          rows = data.map(r => ([formatDate(r.timestamp), r.floor, r.inspectionResult, r.reasoning, r.totalAnchors, r.totalAnchorsNormal, r.totalAnchorsAbnormal,
                                 r.totalAnchorsSuspect, r.totalAnchorsUnknown, r.totalAnchorsUnlinked, r.avgTemperature, r.avgHumidity, r.avgHectopascal]));
          
          if (props.scaffoldSurfaceReportId) {
            // timestamp already shown in title
            columns.shift();
            rows.forEach(r => r.shift());
          }
          if (filters.availableFloors.length == 0 && data) {
            // initial floor filter
            filters.availableFloors = [...new Set(data.map(r => r.floor))];
          }
        }
        else if (filters.targetDataTable == 'scaffold_asset_reports') {
          // Reports (anchor-specific)
          columns = ["Timestamp", { name: tStr("狀態", "Status"), formatter: (cell) => `${cell}` }, tStr("原因", "Reasoning"),
                      tStr("加速度 (X)", "Acceleration (X)"), "Acceleration (Y)", "Acceleration (Z)",
                      { name: "Temperature", formatter: (cell) => formatVal(cell, '°C') }, { name: "Humidity", formatter: (cell) => formatVal(cell, '%') },
                      { name: "Air Pressure", formatter: (cell) => formatVal(cell, 'hPa') }];
          rows = data.map(r => ([formatDate(r.timestamp), r.inspectionResult, r.reasoning, r.accX, r.accY, r.accZ, r.temperature, r.humidity, r.hectopascal]));
        }
        else if (filters.targetDataTable == 'device_logs') {
          // Device Logs
          const arrUniq: any = [...new Map(data.map(v => [JSON.stringify([v.timestamp,v.type]), v])).values()];

          if (props.asset) {
            columns = ["Timestamp", "Event", "Type", "Level"];
            rows = arrUniq.map(r => ([formatDate(r.timestamp), getDeviceLogMsg(r), r.type, r.level]));
          }
          else {
            columns = ["Timestamp", "Device", "Event", "Type", "Level"];
            rows = arrUniq.map(r => ([formatDate(r.timestamp), r.deviceId, getDeviceLogMsg(r), r.type, r.level]));
          }
        }
        else {
          // Device Raw Data
          if (props.asset) {
            columns = ["Timestamp", { name: tStr("傾角", "Inclination"), formatter: (cell) => `${cell}°` },
                        tStr("加速度 (X)", "Acceleration (X)"), "Acceleration (Y)", "Acceleration (Z)",
                        { name: "Temperature", formatter: (cell) => `${cell}°C` }, { name: "Humidity", formatter: (cell) => `${cell}%` },
                        { name: "Air Pressure", formatter: (cell) => `${cell} hPa` }, "Heat Index"];
            rows = data.map(r => ([formatDate(r.timestamp), getRotateDegreeFromAcc(r), r.accX, r.accY, r.accZ, r.temperature, r.humidity, r.hectopascal, r.heatIndex]));
          }
          else {
            columns = ["Timestamp", "Device", { name: tStr("傾角", "Inclination"), formatter: (cell) => `${cell}°` },
                        tStr("加速度 (X)", "Acceleration (X)"), "Acceleration (Y)", "Acceleration (Z)",
                        { name: "Temperature", formatter: (cell) => `${cell}°C` }, { name: "Humidity", formatter: (cell) => `${cell}%` },
                        { name: "Air Pressure", formatter: (cell) => `${cell} hPa` }, "Heat Index"];
            rows = data.map(r => ([formatDate(r.timestamp), r.deviceId, getRotateDegreeFromAcc(r), r.accX, r.accY, r.accZ, r.temperature, r.humidity, r.hectopascal, r.heatIndex]));
          }
        }

        // Render the data table
        grid = new Grid({
          //search: true,
          sort: true,
          fixedHeader: true,
          pagination: { limit: 50, },
          height: '600px',
          width: '100%',
          columns,
          data: rows,
        }).render(document.querySelector(`#${gridDivId}`));
      })
    }

    onMounted(() => {
      refreshData();
    })

    // Watch fromTs changes to adjust toTs relatively
    watch(() => filters.fromTs, (newFromTs, oldFromTs) => {
      if (newFromTs && oldFromTs && filters.toTs) {
        const oldFromDate = new Date(oldFromTs);
        const newFromDate = new Date(newFromTs);
        const timeDiff = newFromDate.getTime() - oldFromDate.getTime();
        
        const oldToDate = new Date(filters.toTs);
        filters.toTs = new Date(oldToDate.getTime() + timeDiff).toISOString();
      }
    });

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      close, filter, closeCircle, checkmark, downloadOutline,

      // variables
      loadingData,
      filters,
      gridDivId,

      // methods
      t, tStr,
      closeModal, getLocalizedStr,
      fd: (d) => (formatDate(d, "YYYY-MM-DD HH:MM")),
      showFile: async (url: any) => {
        const loading = await loadingController.create({});
        await loading.present();
        await downloadFile(url);
        loading.dismiss();
      },

      getPointTypeObj,
      refreshData,

      // device logs
      alertIcons, getDeviceLogMsg,
      updateFilters: (key, val) => {
        filters[key] = val;
        refreshData();
      },

      // popover
      popoverState, popoverEvent,
      setPopoverOpen,

      // export CSV
      exportTableToCSV: () => {
        const { columns, data } = grid.config;
        const objects = data.map(arr => {
          const obj = {};
          arr.forEach((value, index) => {
            const key = columns[index];
            obj[key.name || key] = value;
          });
          return obj;
        });
        const csv = '\ufeff' + Papa.unparse(objects);
        const csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
        const csvURL = window.URL.createObjectURL(csvData);
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'data.csv');
        tempLink.click();
      },
    }
  }
});
