import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import { uploadImage } from '@/services/utils';

export default {
  /**
   * Device
   */
  async upsertDevice(payload: any = {}) {
    console.log(payload.deploymentPhotoLink);
    if (payload.deploymentPhotoLink && !payload.deploymentPhotoLink.startsWith('http')) { // update / new device photo
      payload.deploymentPhotoLink = await uploadImage(payload.deploymentPhotoLink, payload.id || `device-${new Date().valueOf()}`);
    }
    return (await firebase.app().functions('asia-east2').httpsCallable('device-upsertDevice')(payload)).data;
  },
  async updateDevicePointXY(id, pointX, pointY) {
    return (await firebase.app().functions('asia-east2').httpsCallable('device-upsertDevice')({ id, pointX, pointY })).data;
  },
  async deleteDevicePoint(id) {
    //return (await firebase.app().functions('asia-east2').httpsCallable('device-upsertDevice')({ id, projectId: '' })).data;
    return (await firebase.app().functions('asia-east2').httpsCallable('device-upsertDevice')({ id, status: 'trashed' })).data;
  },

  /**
   * Device Alerts
   */
  async updateDeviceAlert(alertId: any, payload: any = {}) {
    return (await firebase.app().functions('asia-east2').httpsCallable('device-updateDeviceAlert')({ alertId, ...payload })).data;
  },
  async getTodayDeviceAlerts(deviceId: any = null, projectId: any = null) {
    return (await firebase.app().functions('asia-east2').httpsCallable('device-getTodayDeviceAlerts')({ deviceId, projectId })).data; 
  },

  /**
   * AI Cam Edge
   */
  async fetchEdgeChannels(device) {
    return (await firebase.app().functions('asia-east2').httpsCallable('device-fetchEdgeChannels')({ device })).data; 
  },
}